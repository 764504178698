export default {
  menu: [
    {
      label: "Transacciones",
      icon: "pi pi-fw pi-chart-bar icon-color pt-1",
      to: "/transactions",
    },
    {
      label: "Configuraciones",
      icon: "pi pi-fw pi-clone icon-color pt-1",
      to: "/config/exchange-rates",
    },
  ],
};
